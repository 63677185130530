<template lang="pug">
    .template-list
        emptyView(v-if="templateList.length === 0" desc="请在电脑端创建快递模板")
        van-cell-group.mb12(v-else v-for="it in templateList")
            van-cell(:title="it.name" icon="logistics")
                template(#right-icon)
                    <!--van-switch(v-model="status" size="24" active-color="#dd4433")-->
            van-cell
                template(#default)
                    p.f13 店铺名称：{{it.mallName}}
                    p.f13(style="display:flex")
                        span(style="min-width:65px") 网点地址：
                        span {{it.address}}
                    p.f13
                        span 面单余额：
                        span.green {{it.quantity}}
            <!--van-cell-->
            <!--template(#default)-->
            <!--p.fr.f13-->
            <!--a 编辑-->
            <!--a.ml10.red 删除-->
        <!--FooterButton(:title="'新增快递模板'" @save="showAddTemplates")-->

</template>

<script>
  import FooterButton from '../common/footerButton'
  import emptyView from '../common/emptyView'
  import {mapGetters, mapActions} from "vuex";

  export default {
    components: {FooterButton,emptyView},
    computed: {
      ...mapGetters(['templateList'])
    },
    data() {
      return {
        status: true,
      }
    },
    methods: {
      ...mapActions(['updateWaybillBalanceAction']),
      showAddTemplates() {
      }
    },
    mounted() {
      this.updateWaybillBalanceAction();
    }
  }

</script>

<style lang="stylus">
    .template-list
        padding 12px
        .van-icon
            color $active
        .van-cell__title
            font-weight bold
</style>
